<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/my/shop/coupon_detail/add')">添加店铺优惠券</el-button>
			</div>
			<div class="Right">
				<span style="margin-left: 10px;margin-right: 10px;">
					<el-input placeholder="请输入查询内容" v-model="Search.Name"></el-input>
				</span>
				<span>
					<el-button type="primary" @click="GetList(Page)">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">

				<div class="C2" style="padding-left: 10px;">名称</div>
				<div class="C3">类型</div>
				<div class="C3">状态</div>
				<div class="C5">金额</div>
				<div class="C5">创建时间</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in CouponList" :key="item.Id">
				<li>
	
					<div class="C2" style="padding-left: 10px;">{{item.Name}}</div>
					
					<div class="C3">
						
						<template v-if="item.ShopId == 'all'">平台券</template>
						<template v-else>店铺券</template>
						
					</div>
					
					<div class="C3">
						
						{{item.StatusName}}
						
					</div>
					<div class="C5">
						满￥{{item.MoneyStart}}减￥{{item.Money}}
					
					</div>
					<div class="C5">{{item.CreatedAt}}</div>
					<div class="C6">
						<el-button size="mini" @click="GetLogList(item)">发放记录</el-button>
						<el-button size="mini" @click="$Jump('/my/shop/coupon_detail/'+item.Id)">编辑</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>


		<el-dialog
		  title="发放记录"
		  :visible="DialogCouponReciveList"
		  width="800px"
		  :before-close="ListDialogClose">
			<div class="Notices" v-if="CouponReciveList.length > 0">
				<vuescroll :ops="vuescroll_ops">
					<div class="NoticeList">
						<li v-for="(notice,noticeI) in CouponReciveList" :key="noticeI">
							<label><label>{{notice.CreatedAt}}</label>发放1张 <span>编号{{notice.Id}}</span></label> 
							<i>{{notice.StatusName}}</i>	
						</li>
					</div>
				</vuescroll>
			</div>
			<div v-else style="text-align: center;padding-bottom: 20px;">
				<i class="el-icon-warning"></i>当前优惠券暂无领取记录
			</div>
		  </el-dialog>
		
	</div>
</template>

<script>
	import vuescroll from 'vuescroll'
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Dialog} from 'element-ui'
	export default {
	  name: 'MyShopCouponList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Status:-1,//全部
				  Id:'',//商品ID
				  Name:'',//关键词
			  },
			  CouponList:[],
			  Page:1,
			  PageSize:15,
			  Total:0,
			  CouponReciveList:[],
			  DialogCouponReciveList:false,
			  vuescroll_ops: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#000000',
			  		opacity: 0.1,
			  	}	
			  },
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-dialog':Dialog,
		vuescroll
	  },
	  created() {
	  	this.StartSeaching()
	  },
	  methods:{
		  ListDialogClose(){
			  this.CouponReciveList = []
			this.DialogCouponReciveList = false  
		  },
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSeaching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Coupon',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
					ShopId:this.$store.getters.getShopId,
					Name:this.Search.Name,
					Id:this.Search.Id,
					Status:this.Search.Status,
					FromCouponChannel:'middle',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.CouponList = res.Data.CouponList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Coupon',
		  			Action: 'Count',
		  			ShopId:this.$store.getters.getShopId,
		  			Name:this.Search.Name,
		  			Id:this.Search.Id,
		  			Status:this.Search.Status,
		  			FromCouponChannel:'middle',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetLogList(_coupon){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'CouponRecive',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:300,
					FromCouponChannel:'middle',
					ShopId:_coupon.ShopId,
					CouponId:_coupon.Id,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.DialogCouponReciveList = true
					this.CouponReciveList = res.Data.CouponList
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.Brand .List{
}
.C1,.C2,.C3,.C4,.C5,.C6{
	width: 150px;
}
.C1{
	padding-left: 10px;
	width: 60px;
	margin-right: 20px;
}
.C1 img{
	width: 60px;
}
.C2{
	flex: 1;
	margin-right: 10px;
}
.C4 i{
	font-style: normal;
	color: rgba(0,0,0,0.3);
	margin-left: 5px;
}
.C5{
	color: rgba(0,0,0,0.3);
}
.C6{
	padding-right: 10px;
	text-align: right;
}
.Brand .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.Brand .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.Brand .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}

.Notices{
	max-height: 400px;	
}
.NoticeList li{
	display: flex;
	padding-bottom: 10px;
	padding-right: 20px;
}
.NoticeList li label{
	flex: 1;
	font-size: 1.1rem;
}
.NoticeList li label label{
	margin-right: 10px;
	color: rgba(0,0,0,0.3);
}
.NoticeList li span{
	background-color: rgba(200,0,0,1);
	color: #FFFFFF;
	padding: 2px 10px;
	cursor: pointer;
}
.NoticeList li i{
	background-color: rgba(0,0,0,0.1);
	color: #999999;
	padding: 2px 10px;
	font-style: normal;
}
</style>
